//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getDictionary,
         getDictFLJ,
         getDictWP,
         getIOSDevice,
         getElections,
         getUpcomingElections,
         getUpcomingElectionsFiltered,
         getNextElection,
         getDeadlineElection,
         getDeadlines,
         getStateBallotRequestSubmitOptions,
         getStateRegistrationSubmitOptions,
         getStateRules,
         getNewVfaDate,
         reformatRules } from '~/utils/butterUtils.js'
import { MS_PER_DAY,
         PREFER_NOT_TO_ANSWER,
         getVFAParameters } from '~/utils/VFAParameters'
import { mapState, mapGetters, mapMutations } from "vuex"
import snarkdown from 'snarkdown'
import download from 'downloadjs'
import { event } from 'vue-gtag'

export default {
  name: 'SignAndSubmit',
  middleware: 'verify-request',
  data () {
    return {
      attachmentRequired: false,
      isSignatureModalActive: false,
      isSigning: false,
      hasCamera: false,
      downloadAttrSupported: false,
      needsMsSaveOrOpenBlob: false,
      downloadStatus: 0,
      downloadStatusText: '0',
      nDownloadsFPCA: 0,
      nDownloadsInstructions: 0,
      signStep: null,
      fpca: null,
      fpcaImagePng: '',

      pdfBytesFpcaForm: '',
      pdfBytesFpcaFormBase64: '',
      pdfBytesFpcaInstructions: '',
      isRenderingFpcaForm: true,
      isRenderingFpcaFormBase64: true,
      isRenderingFpcaInstructionSheet: true,
      isRenderingFpcaFormImage: true,

      submitMethod: null,
      submitMethodSelected: false,
      voterNextSteps: false,
      voterSelectedRegistration: '',
      postDataEntryStep: 'reviewForm',
      showFpcaImagePng: true,
      signature: '',
      signatureStep: 'no',
      signatureAdded: false,
    }
  },
  beforeDestroy () {
    window.onbeforeunload = null
  },
  created () {
    window.onbeforeunload = this.beforeunloadHandler
  },
  mounted () {
    if (this.joinDa && !this.$store.state.userdata.user.isDA) {
    }

    let feat = this
    if (process.browser) {

      /**
       * 2022-11-03 John Yee
       * Do we need the next two lines?  We don't download the PDF from the server.
       */
      feat.downloadAttrSupported = 'download' in document.createElement('a')
      feat.needsMsSaveOrOpenBlob = Boolean(window.navigator.msSaveOrOpenBlob)

      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        feat.hasCamera = false
        return
      }
      navigator.mediaDevices
        .enumerateDevices()
        .then(function (devices) {
          feat.hasCamera =
            devices.filter(x => x.kind === 'videoinput').length > 0
        })
        .catch(function (err) {
          feat.hasCamera = false
          console.error(err.name + ': ' + err.message)
        })
    }

    if (this.stateRules) {
      for (let ii=0; ii<this.stateRules.specialinput.length; ii++) {
        let isenabled = this.stateRules.specialinput[ii].isenabled
        if (isenabled) {
          let isTriggered = this.isStateRulesSpecialInputTriggered (this.stateRules.specialinput[ii],
                            this.voterIsRegistered, this.voterClass, this.party, this.identificationStatus)
          if (isTriggered && this.stateRules.specialinput[ii].attachment_is_required) {
            this.attachmentRequired = true
          }
        }
      } 
    }
  },
  computed: {
    showTestData () {
      return this.$store.state.showTestData
    },
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    lang () {
      return this.$i18n.locale.toLowerCase()
    },
    isIOSDevice () {
      return getIOSDevice()
    },
    dateFormat () {
      return this.$i18n.locales.filter(item => item.code === this.$i18n.locale)[0].iso
    },
    isRenderingPDF () {
      return this.isRenderingFpcaForm || this.isRenderingFpcaInstructionSheet || this.isRenderingFpcaFormImage
    },
    stateRules () {
      if (this.votState) {
        let scr1 = this.butterStateVotingRules.find(x => x.stateid.toLowerCase().slice(0, 2) === this.votState.toString().toLowerCase())
        return getStateRules(scr1, this.lang, this.$store.state.showDictionaryKeys)
      } else {
        return undefined
      }
    },
    isStateSpecial () {
      return (this.stateRules.dashboardspecial ? true : false) || (this.stateRules.dashboardspecialdeadline ? true : false)
    },
    elections () {
      const scr0 = this.butterStateElections
      if (scr0) {
        const scr1 = scr0.filter(item => item.stateid.toLowerCase().slice(0, 2) === this.votState.toLowerCase())
        return getElections(scr1, this.FWAB_TRIGGER_DEFAULT, this.lang)
      } else {
        return []
      }
    },
    upcomingElections () {
      /** 2023-11-20 John Yee
       *  per conversation with Heidi
       *  all elections should be visible to any voter
       *  i.e. no filtering based on military or registration status
       *
       *  but, if she changes her mind, then here is where we filter the elections ...
       *    return getUpcomingElectionsFiltered (this.elections, stateRules.militaryseparate, stateRules.votertypesused, stateRules.usesvoterisregistered, this.voterType, this.voterIsRegistered)
       * 
       *  note: militaryseparate to be removed eventually
       */
      /* return getUpcomingElectionsFiltered (this.elections, this.stateRules.militaryseparate, this.stateRules.usesvoterisregistered, this.voterType, this.voterIsRegistered) */
      return getUpcomingElections (this.elections)
    },
    deadlineElection () {
      /** 2022-10-24 John Yee
       *  override per VFA team conference call:
       *  ignore voter's registration status
       *  use the next election because the deadline election is election day
       */
      // const le = getDeadlineElection (this.upcomingElections, this.voterIsRegistered)
      const le = getNextElection (this.upcomingElections)
      this.$store.commit('saveDeadlineElection', le)
      return le
    },
    /**
     * 2022-11-04 John Yee
     * consolidating old voternextsteps.vue into this page
     * the old voternextsteps.vue uses the following deadlines computed property
     * note the last two parameters are null
     * 
      deadlines () {
        return getDeadlines(this.deadlineElection, null, null)
      },
     *
     */
    deadlines () {
      let d = getDeadlines (this.deadlineElection, this.voterType, this.voterIsRegistered)

      /**
       * 2022-08-10 John Yee
       * Bug: If there is no deadline election, then clicking on a submission option ("Email later", etc.)
       * will start an infinite loop of calls to deadlines (), voterDeadlines (), etc.
       * I haven't a clue why the infinite loop starts for an empty deadlineElection.
       * 
       * Workaround: Make a reference to deadlineElection.
       * e.g. let de = this.deadlineElection
       * 
       * I haven't a clue why this works.
       * 
       * Also: If you remove the line this.$store.commit('saveDeadlines', d), then there is no infinite loop.
       * I can't do this because I want to store the deadlines.
       * This implies to me that there is an interaction with the store and an empty deadline election.
       * 
       * 2022-10-19 John Yee
       * I removed store.state.deadlines since this was not retrieved anywhere.
       */
      let de = this.deadlineElection

      return d
    },
    voterDeadlines () {
      // deadlines by method: email, fax, online, postal mail, mail

      // guarantee differentiation with string "Postal Mail" or "Mail"
      let emailReplacementString = "emaiil"
      let s = JSON.stringify(this.deadlines)
      let d = JSON.parse(s.replace(/email/g,emailReplacementString))

      let email = { registration: [ ],
                    ballotrequest: [ ],
                    ballotreturn: [ ]
                  }
      let fax = { registration: [ ],
                  ballotrequest: [ ],
                  ballotreturn: [ ]
                }
      let online = { registration: [ ],
                     ballotrequest: [ ],
                     ballotreturn: [ ]
                   } 
      let mail = { registration: [ ],
                   ballotrequest: [ ],
                   ballotreturn: [ ]
                 }

      // email
      for (let ii=0; ii<d.registration.length; ii++) {
        if (d.registration[ii].rule.indexOf("not-required")>-1) {
          let m = {
                    "deadline": "no deadline",
                    "deadlineTime": "",
                    "note": d.registration[ii].note.replace(emailReplacementString, "email")
                  }
          email.registration.push(m)
        }
        if (d.registration[ii].rule.indexOf(emailReplacementString)>-1) {
          const dd = new Date(d.registration[ii].date.slice(0,10)+"T00:00:00+00:00").toLocaleDateString(
                        this.dateFormat,
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          timeZone: "UTC"
                        }
                      )
          let m = {
                    "deadline": "Email: Received by " + dd,
                    "deadlineTime": (d.registration[ii].time!=='12:00AM' && d.registration[ii].time!=='11:59PM') ? d.registration[ii].time : '',
                    "note": d.registration[ii].note.replace(emailReplacementString, "email")
                  }
          email.registration.push(m)
        }
      }
      for (let ii=0; ii<d.ballotrequest.length; ii++) {
        if (d.ballotrequest[ii].rule.indexOf(emailReplacementString)>-1) {
          const dd = new Date(d.ballotrequest[ii].date.slice(0,10)+"T00:00:00+00:00").toLocaleDateString(
                        this.dateFormat,
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          timeZone: "UTC"
                        }
                      )
          let m = {
                    "deadline": "Email: Received by "+ dd,
                    "deadlineTime": (d.ballotrequest[ii].time!=='12:00AM' && d.ballotrequest[ii].time!=='11:59PM') ? d.ballotrequest[ii].time : '',
                    "note": d.ballotrequest[ii].note.replace(emailReplacementString, "email")
                  }
          email.ballotrequest.push(m)
        }
      }
      for (let ii=0; ii<d.ballotreturn.length; ii++) {
        if (d.ballotreturn[ii].rule.indexOf(emailReplacementString)>-1) {
          const dd = new Date(d.ballotreturn[ii].date.slice(0,10)+"T00:00:00+00:00").toLocaleDateString(
                        this.dateFormat,
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          timeZone: "UTC"
                        }
                      )
          let m = {
                    "deadline": "Email: Received by "+ dd,
                    "deadlineTime": (d.ballotreturn[ii].time!=='12:00AM' && d.ballotreturn[ii].time!=='11:59PM') ? d.ballotreturn[ii].time : '',
                    "note": d.ballotreturn[ii].note.replace(emailReplacementString, "email")
                  }
          email.ballotreturn.push(m)
        }
      }

      // fax
      for (let ii=0; ii<d.registration.length; ii++) {
        if (d.registration[ii].rule.indexOf("not-required")>-1) {
          let m = {
                    "deadline": "no deadline",
                    "deadlineTime": "",
                    "note": d.registration[ii].note.replace(emailReplacementString, "email")
                  }
          fax.registration.push(m)
        }
        if (d.registration[ii].rule.indexOf("fax")>-1) {
          const dd = new Date(d.registration[ii].date.slice(0,10)+"T00:00:00+00:00").toLocaleDateString(
                        this.dateFormat,
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          timeZone: "UTC"
                        }
                      )
          let m = {
                    "deadline": "Fax: Received by "+ dd,
                    "deadlineTime": (d.registration[ii].time!=='12:00AM' && d.registration[ii].time!=='11:59PM') ? d.registration[ii].time : '',
                    "note": d.registration[ii].note.replace(emailReplacementString, "email")
                  }
          fax.registration.push(m)
        }
      }
      for (let ii=0; ii<d.ballotrequest.length; ii++) {
        if (d.ballotrequest[ii].rule.indexOf("fax")>-1) {
          const dd = new Date(d.ballotrequest[ii].date.slice(0,10)+"T00:00:00+00:00").toLocaleDateString(
                        this.dateFormat,
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          timeZone: "UTC"
                        }
                      )
          let m = {
                    "deadline": "Fax: Received by "+ dd,
                    "deadlineTime": (d.ballotrequest[ii].time!=='12:00AM' && d.ballotrequest[ii].time!=='11:59PM') ? d.ballotrequest[ii].time : '',
                    "note": d.ballotrequest[ii].note.replace(emailReplacementString, "email")
                  }
          fax.ballotrequest.push(m)
        }
      }
      for (let ii=0; ii<d.ballotreturn.length; ii++) {
        if (d.ballotreturn[ii].rule.indexOf("fax")>-1) {
          const dd = new Date(d.ballotreturn[ii].date.slice(0,10)+"T00:00:00+00:00").toLocaleDateString(
                        this.dateFormat,
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          timeZone: "UTC"
                        }
                      )
          let m = {
                    "deadline": "Fax: Received by "+ dd,
                    "deadlineTime": (d.ballotreturn[ii].time!=='12:00AM' && d.ballotreturn[ii].time!=='11:59PM') ? d.ballotreturn[ii].time : '',
                    "note": d.ballotreturn[ii].note.replace(emailReplacementString, "email")
                  }
          fax.ballotreturn.push(m)
        }
      }

      // online
      for (let ii=0; ii<d.registration.length; ii++) {
        if (d.registration[ii].rule.indexOf("not-required")>-1) {
          let m = {
                    "deadline": "no deadline",
                    "deadlineTime": "",
                    "note": d.registration[ii].note.replace(emailReplacementString, "email")
                  }
          online.registration.push(m)
        }
        if (d.registration[ii].rule.indexOf("online")>-1) {
          const dd = new Date(d.registration[ii].date.slice(0,10)+"T00:00:00+00:00").toLocaleDateString(
                        this.dateFormat,
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          timeZone: "UTC"
                        }
                      )
          let m = {
                    "deadline": "Online: Received by "+ dd,
                    "deadlineTime": (d.registration[ii].time!=='12:00AM' && d.registration[ii].time!=='11:59PM') ? d.registration[ii].time : '',
                    "note": d.registration[ii].note.replace(emailReplacementString, "email")
                  }
          online.registration.push(m)
        }
      }
      for (let ii=0; ii<d.ballotrequest.length; ii++) {
        if (d.ballotrequest[ii].rule.indexOf("online")>-1) {
          const dd = new Date(d.ballotrequest[ii].date.slice(0,10)+"T00:00:00+00:00").toLocaleDateString(
                        this.dateFormat,
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          timeZone: "UTC"
                        }
                      )
          let m = {
                    "deadline": "Online: Received by "+ dd,
                    "deadlineTime": (d.ballotrequest[ii].time!=='12:00AM' && d.ballotrequest[ii].time!=='11:59PM') ? d.ballotrequest[ii].time : '',
                    "note": d.ballotrequest[ii].note.replace(emailReplacementString, "email")
                  }
          online.ballotrequest.push(m)
        }
      }
      for (let ii=0; ii<d.ballotreturn.length; ii++) {
        if (d.ballotreturn[ii].rule.indexOf("online")>-1) {
          const dd = new Date(d.ballotreturn[ii].date.slice(0,10)+"T00:00:00+00:00").toLocaleDateString(
                        this.dateFormat,
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          timeZone: "UTC"
                        }
                      )
          let m = {
                    "deadline": "Online: Received by "+ dd,
                    "deadlineTime": (d.ballotreturn[ii].time!=='12:00AM' && d.ballotreturn[ii].time!=='11:59PM') ? d.ballotreturn[ii].time : '',
                    "note": d.ballotreturn[ii].note.replace(emailReplacementString, "email")
                  }
          online.ballotreturn.push(m)
        }
      }

      // Postal mail
      for (let ii=0; ii<d.registration.length; ii++) {
        if (d.registration[ii].rule.indexOf("not-required")>-1) {
          let m = {
                    "deadline": "no deadline",
                    "deadlineTime": "",
                    "note": d.registration[ii].note.replace(emailReplacementString, "email")
                  }
          mail.registration.push(m)
        }
        if (d.registration[ii].rule.indexOf("mail")>-1 && d.registration[ii].rule.indexOf("email")<0) {
          if (d.registration[ii].rule.indexOf("postmarked")>-1) {
            const dd = new Date(d.registration[ii].date.slice(0,10)+"T00:00:00+00:00").toLocaleDateString(
                        this.dateFormat,
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          timeZone: "UTC"
                        }
                      )
            let m = {
                      "deadline": "Postal mail: Postmarked by "+ dd,
                      "deadlineTime": (d.registration[ii].time!=='12:00AM' && d.registration[ii].time!=='11:59PM') ? d.registration[ii].time : '',
                      "note": d.registration[ii].note.replace(emailReplacementString, "email")
                    }
            mail.registration.push(m)
          }

          if (d.registration[ii].rule.indexOf("received")>-1) {
            const dd = new Date(d.registration[ii].date.slice(0,10)+"T00:00:00+00:00").toLocaleDateString(
                        this.dateFormat,
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          timeZone: "UTC"
                        }
                      )
            let m = {
                      "deadline": "Postal mail: Received by "+ dd,
                      "deadlineTime": (d.registration[ii].time!=='12:00AM' && d.registration[ii].time!=='11:59PM') ? d.registration[ii].time : '',
                      "note": d.registration[ii].note.replace(emailReplacementString, "email")
                    }
            mail.registration.push(m)
          }

          if (d.registration[ii].rule.indexOf("sent")>-1) {
            const dd = new Date(d.registration[ii].date.slice(0,10)+"T00:00:00+00:00").toLocaleDateString(
                        this.dateFormat,
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          timeZone: "UTC"
                        }
                      )
            let m = {
                      "deadline": "Postal mail: Sent by "+ dd,
                      "deadlineTime": (d.registration[ii].time!=='12:00AM' && d.registration[ii].time!=='11:59PM') ? d.registration[ii].time : '',
                      "note": d.registration[ii].note.replace(emailReplacementString, "email")
                    }
            mail.registration.push(m)
          }
        }
      }
      for (let ii=0; ii<d.ballotrequest.length; ii++) {
        if (d.ballotrequest[ii].rule.indexOf("mail")>-1 && d.ballotrequest[ii].rule.indexOf("email")<0) {
          if (d.ballotrequest[ii].rule.indexOf("postmarked")>-1) {
            const dd = new Date(d.ballotrequest[ii].date.slice(0,10)+"T00:00:00+00:00").toLocaleDateString(
                        this.dateFormat,
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          timeZone: "UTC"
                        }
                      )
            let m = {
                      "deadline": "Postal mail: Postmarked by "+ dd,
                      "deadlineTime": (d.ballotrequest[ii].time!=='12:00AM' && d.ballotrequest[ii].time!=='11:59PM') ? d.ballotrequest[ii].time : '',
                      "note": d.ballotrequest[ii].note.replace(emailReplacementString, "email")
                    }
            mail.ballotrequest.push(m)
          }

          if (d.ballotrequest[ii].rule.indexOf("received")>-1) {
            const dd = new Date(d.ballotrequest[ii].date.slice(0,10)+"T00:00:00+00:00").toLocaleDateString(
                        this.dateFormat,
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          timeZone: "UTC"
                        }
                      )
            let m = {
                      "deadline": "Postal mail: Received by "+ dd,
                      "deadlineTime": (d.ballotrequest[ii].time!=='12:00AM' && d.ballotrequest[ii].time!=='11:59PM') ? d.ballotrequest[ii].time : '',
                      "note": d.ballotrequest[ii].note.replace(emailReplacementString, "email")
                    }
            mail.ballotrequest.push(m)
          }

          if (d.ballotrequest[ii].rule.indexOf("sent")>-1) {
            const dd = new Date(d.ballotrequest[ii].date.slice(0,10)+"T00:00:00+00:00").toLocaleDateString(
                        this.dateFormat,
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          timeZone: "UTC"
                        }
                      )
            let m = {
                      "deadline": "Postal mail: Sent by "+ dd,
                      "deadlineTime": (d.ballotrequest[ii].time!=='12:00AM' && d.ballotrequest[ii].time!=='11:59PM') ? d.ballotrequest[ii].time : '',
                      "note": d.ballotrequest[ii].note.replace(emailReplacementString, "email")
                    }
            mail.ballotrequest.push(m)
          }
        }
      }
      for (let ii=0; ii<d.ballotreturn.length; ii++) {
        if (d.ballotreturn[ii].rule.indexOf("mail")>-1 && d.ballotreturn[ii].rule.indexOf("email")<0) {
          if (d.ballotreturn[ii].rule.indexOf("postmarked")>-1) {
            const dd = new Date(d.ballotreturn[ii].date.slice(0,10)+"T00:00:00+00:00").toLocaleDateString(
                        this.dateFormat,
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          timeZone: "UTC"
                        }
                      )
            let m = {
                      "deadline": "Postal mail: Postmarked by "+ dd,
                      "deadlineTime": (d.ballotreturn[ii].time!=='12:00AM' && d.ballotreturn[ii].time!=='11:59PM') ? d.ballotreturn[ii].time : '',
                      "note": d.ballotreturn[ii].note.replace(emailReplacementString, "email")
                    }
            mail.ballotreturn.push(m)
          }

          if (d.ballotreturn[ii].rule.indexOf("received")>-1) {
            const dd = new Date(d.ballotreturn[ii].date.slice(0,10)+"T00:00:00+00:00").toLocaleDateString(
                        this.dateFormat,
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          timeZone: "UTC"
                        }
                      )
            let m = {
                      "deadline": "Postal mail: Received by "+ dd,
                      "deadlineTime": (d.ballotreturn[ii].time!=='12:00AM' && d.ballotreturn[ii].time!=='11:59PM') ? d.ballotreturn[ii].time : '',
                      "note": d.ballotreturn[ii].note.replace(emailReplacementString, "email")
                    }
            mail.ballotreturn.push(m)
          }

          if (d.ballotreturn[ii].rule.indexOf("sent")>-1) {
            const dd = new Date(d.ballotreturn[ii].date.slice(0,10)+"T00:00:00+00:00").toLocaleDateString(
                        this.dateFormat,
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          timeZone: "UTC"
                        }
                      )
            let m = {
                      "deadline": "Postal mail: Sent by "+ dd,
                      "deadlineTime": (d.ballotreturn[ii].time!=='12:00AM' && d.ballotreturn[ii].time!=='11:59PM') ? d.ballotreturn[ii].time : '',
                      "note": d.ballotreturn[ii].note.replace(emailReplacementString, "email")
                    }
            mail.ballotreturn.push(m)
          }
        }
      }

      return {
        "email": email,
        "fax": fax,
        "online": online,
        "mail": mail
      }
    },
    voterType () {
      /*
        2024-03-12 John Yee

        voterClass values should be limited to:
          'intendToReturn'
          'uncertainReturn'
          'neverResided'
          'military'
          'milSpouse'
        These values ceom from components/FormVoterClassRadioButton.vue

        I included:
          'civilian'
          'all-voters'
        because they are valid values from the Butter database.

        from state voting rules do something like...
          if (this.stateRules.militaryseparate) {
            // use "civilian" or "military"
          }

          if (this.stateRules.votertypesused) {
            // use specific civilian and military types
          }
        TO DO:
          (1) synchronize the variables voterClass and voterType
          (2) synchronize the variable values among Butter and the source code components and pages
              e.g. synchronize voterClass === 'intendtoreturn' with voterType === 'civilian-intend-to-return' 

        BE CAREFUL:
          (1) for states that just distinuish between generic civilian and generic military
              we need voterType === 'civilian' and voterType === 'military'
              BUT
              /pages/request/voting-information "Which voter category best describes you?"
              does not have generic "Civilian" and "Military" buttons

              The code that assigns category may have to change the default values of the buttons 
              into generic values.

          (2) ? Should we change the button "Military - Active Duty" to return "military-active-duty"
              rather than "military" as it does now ?

        IN Butter:
          'request.voterClass.all-voters': butterDictionary.I46,
          'request.voterClass.allVoters': butterDictionary.I46,

          'request.voterClass.civilian': butterDictionary.C18,
          'request.voterClass.civilianType': butterDictionary.C18,
          'request.voterClass.civilian-intend-to-return': butterDictionary.Q08,

          'request.voterClass.uncertainReturn': butterDictionary.H36,
          'request.voterClass.civilian-uncertain-return': butterDictionary.Q09,

          'request.voterClass.neverResided': butterDictionary.H33,
          'request.voterClass.civilian-never-resided-in-us': butterDictionary.Q10,

          'request.voterClass.military': butterDictionary.H30,
          'request.voterClass.military-active-duty': butterDictionary.C19,
          'request.voterClass.military-dependent': butterDictionary.H32,
          'request.voterClass.military-spouse': butterDictionary.C20,
          'request.voterClass.militaryType': butterDictionary.H31,
          'request.voterClass.milSpouse': butterDictionary.H32,
      */
      let vt
      if (this.voterClass) {
        let vc = this.voterClass.toLowerCase()
        switch (vc) {
          case 'intendtoreturn':
            vt ='civilian-intend-to-return';
            break;
          case 'uncertainreturn':
            vt ='civilian-uncertain-return';
            break;
          case 'neverresided':
            vt ='civilian-never-resided-in-us';
            break;
          case 'military':
            vt='military-active-duty';
            break;
          case 'milspouse':
            vt='military-spouse';
            break;
          default:
            vt = 'civilian-uncertain-return';
        }
      } else {
        vt = 'all-voters' // give vt a non-empty value
      }

      /**
       * accommodating legacy logic for voterClass and state voting rule militaryseparate
       * I want to eliminate this code.  see TO DO.
       */
      /**
       * possible incompatibility?
       * both "militaryseparate" and "votertypesused" are selected
       * which one takes priority?
       */
      /*
      if (this.stateRules.militaryseparate && vt.includes('civilian')) {
        // collapse all specific civilian statuses into one generic type 'civilian'
        vt = 'civilian'
      }
      if (this.stateRules.militaryseparate && vt.includes('military')) {
        // revert to original military voterClass
        vt = this.voterClass.toLowerCase()
      }
      */

      /*
      if (this.stateRules.militaryseparate) {
        if (this.voterClass) {
          if (vt.includes('civilian')) {
            // collapse all specific civilian statuses into one generic type 'civilian'
            vt = 'civilian'
          }
        
          if (vt.includes('military')) {
            // revert to original military voterClass
            vt = this.voterClass.toLowerCase()
          }
        }
      }
      */
      /*
      if (!this.stateRules.votertypesused) {
        if (this.voterClass) {
          if (vt.includes('civilian')) {
            // collapse all specific civilian statuses into one generic type 'civilian'
            vt = 'civilian'
          }
        
          if (vt.includes('military')) {
            // revert to original military voterClass
            vt = this.voterClass.toLowerCase()
          }
        }
      }
      */

      let mil = this.stateRules.militaryseparate
      let vtu = this.stateRules.votertypesused

      if (mil) {
        switch (vtu) {
          case 'civilian-only':
            // incompatible combination of mil and vtu; keep previously calculated vt
            break;

          case 'military-only':
            if (vt.includes('civilian')) {
              // collapse all specific civilian statuses into one generic type 'civilian'
              vt = 'civilian'
            }
            break;

          case 'civilian-and-military':
            // incompatible combination of mil and vtu; keep previously calculated vt
            break;
            
          default:
            if (vt.includes('civilian')) {
              // collapse all specific civilian statuses into one generic type 'civilian'
              vt = 'civilian'
            }
        }
      } else {
        switch (vtu) {
          case 'civilian-only':
            // keep previously calculated vt
            break;

          case 'military-only':
            if (vt.includes('civilian')) {
              // collapse all specific civilian statuses into one generic type 'civilian'
              vt = 'civilian'
            }
            break;

          case 'civilian-and-military':
            // keep previously calculated vt
            break;
            
          default:
            if (vt.includes('civilian')) {
              // collapse all specific civilian statuses into one generic type 'civilian'
              vt = 'civilian'
            }
        }
      }

      return (this.voterClass) ? vt : this.$store.state.voterType
    },
    voterIsRegistered () {
      // 2022-03-25 per Heidi: registration==="unsure" to be treated as registration==="notregistered"
      let vir = (this.currentRequest.isRegistered) ? this.currentRequest.isRegistered.toLowerCase() : "notregistered"
      return (vir === "registered") ? "registered" : "notregistered"
    },
    dict () {
      if (this.butterDictionary) {
        return getDictionary(this.butterDictionary, this.lang, 'd', this.$store.state.showDictionaryKeys)
      } else {
        return { "ERROR" : "error"}
      }
    },
    dictwys () {
      if (this.butterDictionaryWYSIWYG) {
        return getDictionary(this.butterDictionaryWYSIWYG, this.lang, 'w', this.$store.state.showDictionaryKeys)
      } else {
        return { "ERROR" : "error"}
      }
    },
    allowedSubmitMethods () {
      let voterIsRegistered = this.voterIsRegistered
      let voterType = (this.voterType) ? this.voterType : "all-voters"

      let isRegistrationRequired = voterIsRegistered!=="registered"
      let isBallotRequestRequired = true
      let isBallotReturnRequired = true

      let mayEmail = false
      let mayMail = false
      let mayFax = false
      let mayOnline = false

      if (Object.keys(this.deadlineElection).length===0) {
        let so
        if (voterIsRegistered==="registered") {
          so = getStateBallotRequestSubmitOptions(this.stateRules)
        } else {
          so = getStateRegistrationSubmitOptions(this.stateRules)
        }

        if (this.stateRules.usesVoterIsRegistered && voterIsRegistered!=="registered") {
          mayEmail = so.mayEmail && (this.stateRules.unregistered_voter_submit_options.includes('email') || this.stateRules.unregistered_voter_submit_options.includes('notused'))
          mayMail = so.mayMail && (this.stateRules.unregistered_voter_submit_options.includes('postalmail') || this.stateRules.unregistered_voter_submit_options.includes('notused'))
          mayFax = so.mayFax && (this.stateRules.unregistered_voter_submit_options.includes('fax') || this.stateRules.unregistered_voter_submit_options.includes('notused'))
          mayOnline = so.mayOnline && (this.stateRules.unregistered_voter_submit_options.includes('online') || this.stateRules.unregistered_voter_submit_options.includes('notused'))
        } else {
          // assume there are no restrictions for a registered voter
          mayEmail = so.mayEmail
          mayMail = so.mayMail
          mayFax = so.mayFax
          mayOnline = so.mayOnline
        }

        // trap the cases where the LEO does not have a contact point
        mayEmail = mayEmail && !!this.currentRequest.leo.e
        mayFax = mayFax && !!this.currentRequest.leo.f

        return {
          isRegistrationRequired: isRegistrationRequired,
          isBallotRequestRequired: isBallotRequestRequired,
          isBallotReturnRequired: isBallotReturnRequired,
          mayEmail: mayEmail,
          mayMail: mayMail,
          mayFax: mayFax,
          mayOnline: mayOnline
        }
      }

      let registrationRule = this.deadlineElection.rules.registration
                          .filter(
                                  x=>(
                                      (x.votertype==='all-voters' || x.votertype===voterType)
                                    &&
                                      (x.voterisregistered==='' || x.voterisregistered===voterIsRegistered || x.rule.includes('not-required'))
                                  )
                                )

      for (let ii=0; ii<registrationRule.length; ii++) {
        let rule = registrationRule[ii].rule

        if (rule!=="not-required") {
          mayEmail = mayEmail || rule.indexOf("email")>-1
          rule = rule.replace("email", "")

          mayMail = mayMail || rule.indexOf("mail")>-1
          mayFax = mayFax || rule.indexOf("fax")>-1
          mayOnline = mayOnline || rule.indexOf("online")>-1
        } else {
          isRegistrationRequired = false
        }
      }

      if (!isRegistrationRequired) {
        let ballotrequestRule = this.deadlineElection.rules.ballotrequest
                            .filter(
                                    x=>(
                                        (x.votertype==='all-voters' || x.votertype===voterType)
                                      &&
                                        (x.voterisregistered==='' || x.voterisregistered===voterIsRegistered || x.rule.includes('not-required'))
                                    )
                                  )

        for (let ii=0; ii<ballotrequestRule.length; ii++) {
          let rule = ballotrequestRule[ii].rule

          if (rule!=="not-required") {
            mayEmail = mayEmail || rule.indexOf("email")>-1
            rule = rule.replace("email", "")

            mayMail = mayMail || rule.indexOf("mail")>-1
            mayFax = mayFax || rule.indexOf("fax")>-1
            mayOnline = mayOnline || rule.indexOf("online")>-1
          } else {
            isBallotRequestRequired = false
          }
        }
      }

      if (!isBallotRequestRequired) {
        let ballotreturnRule = this.deadlineElection.rules.ballotreturn
                            .filter(
                                    x=>(
                                        (x.votertype==='all-voters' || x.votertype===voterType)
                                      &&
                                        (x.voterisregistered==='' || x.voterisregistered===voterIsRegistered || x.rule.includes('not-required'))
                                    )
                                  )

        for (let ii=0; ii<ballotreturnRule.length; ii++) {
          let rule = ballotreturnRule[ii].rule

          if (rule!=="not-required") {
            mayEmail = mayEmail || rule.indexOf("email")>-1
            rule = rule.replace("email", "")

            mayMail = mayMail || rule.indexOf("mail")>-1
            mayFax = mayFax || rule.indexOf("fax")>-1
            mayOnline = mayOnline || rule.indexOf("online")>-1
          } else {
            isBallotReturnRequired = false
          }
        }
      }

      if (!isBallotReturnRequired) {
        let fpcaReturnRule = this.stateRules.fpcaSubmitOptionsRegister

        for (let ii=0; ii<fpcaReturnRule.length; ii++) {
          let rule = fpcaReturnRule[ii].toLowerCase()

          if (rule!=="not-required") {
            mayEmail = mayEmail || rule.indexOf("email")>-1
            rule = rule.replace("email", "")

            mayMail = mayMail || rule.indexOf("mail")>-1
            mayFax = mayFax || rule.indexOf("fax")>-1
            mayOnline = mayOnline || rule.indexOf("online")>-1
          }
        }
      }

      if (this.stateRules.usesVoterIsRegistered && voterIsRegistered!=="registered") {
        mayEmail = mayEmail && (this.stateRules.unregistered_voter_submit_options.includes('email') || this.stateRules.unregistered_voter_submit_options.includes('notused'))
        mayMail = mayMail && (this.stateRules.unregistered_voter_submit_options.includes('postalmail') || this.stateRules.unregistered_voter_submit_options.includes('notused'))
        mayFax = mayFax && (this.stateRules.unregistered_voter_submit_options.includes('fax') || this.stateRules.unregistered_voter_submit_options.includes('notused'))
        mayOnline = mayOnline && (this.stateRules.unregistered_voter_submit_options.includes('online') || this.stateRules.unregistered_voter_submit_options.includes('notused'))
      } else {
        // assume there are no restrictions for a registered voter
        mayEmail = mayEmail
        mayMail = mayMail
        mayFax = mayFax
        mayOnline = mayOnline
      }

      // trap the cases where the LEO does not have a contact point
      mayEmail = mayEmail && !!this.currentRequest.leo.e
      mayFax = mayFax && !!this.currentRequest.leo.f

      return {
        isRegistrationRequired: isRegistrationRequired,
        isBallotRequestRequired: isBallotRequestRequired,
        isBallotReturnRequired: isBallotReturnRequired,
        mayEmail: mayEmail,
        mayMail: mayMail,
        mayFax: mayFax,
        mayOnline: mayOnline
      }
    },
    instructionsObject () {
      // must account for Spanish in the specialDeadline value "email"
      return {
        leoName: this.leoName || '',
        transmitOpts: this.transmitOpts,
        default: this.getDictWP(this.dict.E26, {
          leoName: this.leoName,
          transmitOpts: this.transmitOpts
        }),
        specialDeadline: this.stateRules.dashboardspecialdeadline
          ? this.stateRules.dashboardspecialdeadline
          : (
             !this.stateRules.fpcaSubmitOptionsRegister.map(x=>x.toLowerCase()).includes("email")
             ? this.dict.E16 : ''
            )
      }
    },
    pdfInstructions () {
      if (this.isStateSpecial) {
        return this.getDictWP(this.dict.E24,
          Object.assign({}, this.instructionsObject, {stateSpecial: this.stateRules.dashboardspecial+' '+this.stateRules.fpcasubmitnotes_body})
        ).replace(/\*{2}/gi, '*')
      } else {
        return this.getDictWP(this.dict.E26, {
          leoName: this.leoName,
          transmitOpts: this.transmitOpts
        })
          .concat(
            this.stateRules.fpcaSubmitOptionsRegister.includes('Email')
              ? this.dict.E16
              : ''
          )
          .replace(/\*{2}/gi, '*')
          +' '+this.stateRules.fpcasubmitnotes_body
      }
    },
    isPDFDownloaded () {
      let cr = this.currentRequest
      return (cr.status && cr.status==="formDownloaded") ? true : false
    },
    transmitInstructions () {
      if (this.stateRules.dashboardspecialdeadline) {
        return this.getDictWP(this.dict.E26, {
          leoName: this.leoName,
          transmitOpts: this.transmitOpts
        })
      } else if (this.isStateSpecial) {
        return this.getDictWP(this.dict.E24,
          Object.assign({}, this.instructionsObject, { specialDeadline: '' }, {stateSpecial: this.stateRules.dashboardspecial+' '+this.stateRules.fpcasubmitnotes_body})
        )
      } else {
        return this.getDictWP(this.dict.E26, {
          leoName: this.leoName,
          transmitOpts: this.transmitOpts
        })
      }
    },
    electronicTransmissionNote () {
      if (this.stateRules.dashboardspecialdeadline) {
        return this.stateRules.dashboardspecial+' '+this.stateRules.dashboardspecialdeadline
      } else if (this.isStateSpecial) {
        return this.getDictWP(this.dict.E24, {
          stateSpecial: this.stateRules.dashboardspecial
        })
      } else return null
    },
    transmitOpts () {
      switch (this.stateRules.fpcaSubmitOptionsRegister.length) {
        case 1:
          return this.getDictFLJ( `request.deadlineLanguage.${this.stateRules.fpcaSubmitOptionsRegister[0].toLowerCase()}`, this.dict )
        case 2:
          return this.getDictWP(this.dict.E20, {
            item1: this.getDictFLJ( `request.deadlineLanguage.${this.stateRules.fpcaSubmitOptionsRegister[0].toLowerCase()}`, this.dict ).toLowerCase(),
            item2: this.getDictFLJ( `request.deadlineLanguage.${this.stateRules.fpcaSubmitOptionsRegister[1].toLowerCase()}`, this.dict ).toLowerCase()
          })
        case 3:
          return this.getDictWP(this.dict.E21, {
            item1: this.getDictFLJ( `request.deadlineLanguage.${this.stateRules.fpcaSubmitOptionsRegister[0].toLowerCase()}`, this.dict ).toLowerCase(),
            item2: this.getDictFLJ( `request.deadlineLanguage.${this.stateRules.fpcaSubmitOptionsRegister[1].toLowerCase()}`, this.dict ).toLowerCase(),
            item3: this.getDictFLJ( `request.deadlineLanguage.${this.stateRules.fpcaSubmitOptionsRegister[2].toLowerCase()}`, this.dict ).toLowerCase()
          })
        case 4:
          return this.getDictWP(this.dict.E22, {
            item1: this.getDictFLJ( `request.deadlineLanguage.${this.stateRules.fpcaSubmitOptionsRegister[0].toLowerCase()}`, this.dict ).toLowerCase(),
            item2: this.getDictFLJ( `request.deadlineLanguage.${this.stateRules.fpcaSubmitOptionsRegister[1].toLowerCase()}`, this.dict ).toLowerCase(),
            item3: this.getDictFLJ( `request.deadlineLanguage.${this.stateRules.fpcaSubmitOptionsRegister[2].toLowerCase()}`, this.dict ).toLowerCase(),
            item4: this.getDictFLJ( `request.deadlineLanguage.${this.stateRules.fpcaSubmitOptionsRegister[3].toLowerCase()}`, this.dict ).toLowerCase()
          })
        default:
          return `mail, email or fax`
      }
    },
    newVoterDeadlineObject () {
      // must find a rule with a date, we cascade down until we find one
      let elections = this.translatedDeadlineElection.filter(x => x.ruleType === 'Registration')

      if (elections.length===0 || !elections[0].ruleDate) {
        elections = this.translatedDeadlineElection.filter(x => x.ruleType === 'Ballot Request')
      }

      if (elections.length===0 || !elections[0].ruleDate) {
        elections = this.translatedDeadlineElection.filter(x => x.ruleType === 'Ballot Return')
      }

      return this.voterDeadlineObject(elections, this.stateRules, this.dateFormat)
    },
    registeredVoterDeadlineObject () {
      // must find a rule with a date, we cascade down until we find one
      let elections = this.translatedDeadlineElection.filter(x => x.ruleType === 'Ballot Request')

      if (elections.length===0 || !elections[0].ruleDate) {
        elections = this.translatedDeadlineElection.filter(x => x.ruleType === 'Ballot Return')
      }

      return this.voterDeadlineObject(elections, this.stateRules, this.dateFormat)
    },
    deadlineLanguage () {
      // 2022-03-25 per Heidi: registration==="unsure" to be treated as registration==="notregistered"

      /**
       * 2022-12-31 John Yee
       * legacy production of pdf file is different from local production of pdf file
       * probably because legacy production uses the backend server
       * ? can we get them to produce the same pdf ?
       * 
       */
      if (this.translatedDeadlineElection.length === 0) {
        return 'There are no elections currently scheduled for '+this.getDictFLJ(`states.${this.votState}`, this.dict)+'.  Voters should send in an FPCA every calendar year.'
      } else {
        switch (this.isRegistered) {
          case 'notregistered':
            /* let case1 = this.getDictWP( this.dict.E19, this.newVoterDeadlineObject ) */
            let case1 = this.getDictWP( this.dict.N44, this.newVoterDeadlineObject )
            return decodeURI(encodeURI(case1).replace(/\%5Cn/gi, '\%0A').replace(/\%5Ct/gi, '\%09'))
          case 'registered':
            /* let case2 = this.getDictWP( this.dict.E23, this.registeredVoterDeadlineObject ) */
            let case2 = this.getDictWP( this.dict.N45, this.registeredVoterDeadlineObject )
            return decodeURI(encodeURI(case2).replace(/\%5Cn/gi, '\%0A').replace(/\%5Ct/gi, '\%09'))
          default:
            /* let casedefault = this.getDictWP( this.dict.E19, this.newVoterDeadlineObject ) */
            let casedefault = this.getDictWP( this.dict.N44, this.newVoterDeadlineObject )
            return decodeURI(encodeURI(casedefault).replace(/\%5Cn/gi, '\%0A').replace(/\%5Ct/gi, '\%09'))
        }
      }
    },
    currentRequest () {
      return this.requests[this.currentRequestIndex]
    },

    firstName () {
      return this.currentRequest && this.currentRequest.firstName
        ? this.currentRequest.firstName
        : ' '
    },
    middleName () {
      return this.currentRequest && this.currentRequest.middleName
        ? this.currentRequest.middleName
        : ' '
    },
    lastName () {
      return this.currentRequest && this.currentRequest.lastName
        ? this.currentRequest.lastName
        : ' '
    },
    identificationStatus () {
      // identification is an object of the form { "ssn": null, "ssn4": null, "ssn9": null, "stateId": null, "noId": true }
      // we have to convert noId to the text to match the slug from the Butter trigger
      return (this.currentRequest.identification.noId) ? "does-not-have-ssn-stateid" : "does-have-ssn-stateid"
    },
    votState () {
      return this.getCurrent.votAdr.S || ''
    },
    email () {
      return this.currentRequest && this.currentRequest.email
        ? this.currentRequest.email.toString()
        : ' '
    },
    fax () {
      return this.getCurrent.fax || ''
    },
    party () {
      return this.currentRequest && (this.currentRequest.party!==PREFER_NOT_TO_ANSWER)
        ? this.currentRequest.party.toString()
        : ' '
    },

    addlInfo () {
      let addlInfoText = ' '
      if (
          this.currentRequest &&
          (this.currentRequest.stateSpecial ||
            (this.currentRequest.identification &&
              this.currentRequest.identification.noId &&
              this.stateRules &&
              this.stateRules.id &&
              this.stateRules.id.length > 0
            )
          )
         )
      {
        addlInfoText =
          this.currentRequest && this.currentRequest.stateSpecial
            ? this.currentRequest.stateSpecial.toString()
            : ' '
        if (
            this.currentRequest.identification &&
            this.currentRequest.identification.noId &&
            this.stateRules &&
            this.stateRules.id &&
            this.stateRules.id.length > 0
           )
        {
          // if "id_when_recballot_email" is non-empty, then suppress " or State issued ID number"
          const s = (this.currentRequest.recBallot==="email" && this.stateRules && this.stateRules.id_when_recballot_email && this.stateRules.id_when_recballot_email.length > 0 && this.stateRules.id_when_recballot_email[0] !== '') ? '' : this.dict.I50
          addlInfoText = this.dict.C50 + s + ". " + addlInfoText
        }
        return addlInfoText
      } else {
        return ''
      }
    },

    date () {
      let d = getNewVfaDate()
      return (
        this.getCurrent.date ||
        `${d.getFullYear()}-${d.getMonth() < 9 ? '0' : ''}${d.getMonth() + 1}-${
          d.getDate() < 10 ? '0' : ''
        }${d.getDate()}`
      )
    },
    newVfaDate () {
      return getNewVfaDate()
    },
    voterClass () {
      return this.currentRequest && this.currentRequest.voterClass
        ? this.currentRequest.voterClass.toString()
        : ' '
    },
    recBallot () {
      return this.currentRequest && this.currentRequest.recBallot
        ? this.currentRequest.recBallot.toString()
        : ' '
    },
    isRegistered () {
      return this.currentRequest ? this.currentRequest.isRegistered : null
    },
    isRegisteredVoter: {
      get() {
        return this.getCurrent.isRegistered
      },
      set(val) {
        this.update({ isRegistered: this.isRegistered === val ? null : val })
      },
    },

    /**
     * 2023-01-29 John Yee
     * candidate for removal
     */
    joinDa () {
      return this.currentRequest ? Boolean(this.currentRequest.joinDa) : false
    },

    leoName () {
      return this.currentRequest.leo && this.currentRequest.leo.n
        ? this.currentRequest.leo.n
        : ''
    },
    translatedDeadlineElection () {
      if (Object.keys(this.deadlineElection).length===0) {
        return []
      }

      let be = JSON.parse(JSON.stringify(this.deadlineElection))
      let ve = [ ] // for legacy VFA elections format
      let reformatted

      reformatted = reformatRules (be.rules.registration, be.state, be.date, this.getDictFLJ('election.'+be.electiontype, this.dict), "Registration", this.dict)
      ve = ve.concat(reformatted)

      reformatted = reformatRules (be.rules.ballotrequest, be.state, be.date, this.getDictFLJ('election.'+be.electiontype, this.dict), "Ballot Request", this.dict)
      ve = ve.concat(reformatted)

      reformatted = reformatRules (be.rules.ballotreturn, be.state, be.date, this.getDictFLJ('election.'+be.electiontype, this.dict), "Ballot Return", this.dict)
      ve = ve.concat(reformatted)

      return ve
    },
    /**
     * 2024-07-27 John Yee
     * States may treat registered and unregistered voters differently.
     */
    StateAcceptsEmailNowFromUnregisteredVoter () {
      return this.stateRules.unregistered_voter_submit_options.includes('emailnow') || this.stateRules.unregistered_voter_submit_options.includes('notused')
    },
    StateAcceptsEmailLaterFromUnregisteredVoter () {
      return (!this.stateRules.unregistered_voter_submit_options.includes('emailnow') && this.stateRules.unregistered_voter_submit_options.includes('email')) || this.stateRules.unregistered_voter_submit_options.includes('notused')
    },
    StateAcceptsFaxFromUnregisteredVoter () {
      return this.stateRules.unregistered_voter_submit_options.includes('fax') || this.stateRules.unregistered_voter_submit_options.includes('notused')
    },
    /**
     * 2023-05-03 John Yee
     * An individual LEO may refuse to accept FPCA submissions from outside the US.
     * This might be due hardware failure e.g. email server is down or fax machine is broken.
     */
    LEOAcceptsEmailNow () {
      return this.currentRequest.leo.refuses_submissions_by ? (this.currentRequest.leo.refuses_submissions_by.length>0 ? !this.currentRequest.leo.refuses_submissions_by.includes('emailnow') : true) : true
    },
    LEOAcceptsEmailLater () {
      return this.currentRequest.leo.refuses_submissions_by ? (this.currentRequest.leo.refuses_submissions_by.length>0 ? !this.currentRequest.leo.refuses_submissions_by.includes('emaillater') : true) : true
    },
    LEOAcceptsFax () {
      return this.currentRequest.leo.refuses_submissions_by ? (this.currentRequest.leo.refuses_submissions_by.length>0 ? !this.currentRequest.leo.refuses_submissions_by.includes('fax') : true) : true
    },
    leo_sendout_ballots () {
      return LEO_SENDOUT_BALLOTS_TRIGGER
    },
    voterFWAB () {
      return this.deadlines.FWAB.daysToFWAB<=0
    },
    image () {
      return this.butterVFAGraphics ? this.butterVFAGraphics.fpca_blank : null
    },
    expectedBallotReceiptDate () {
      /**
       * if (electionday-today<LEO_SENDOUT_BALLOTS_TRIGGER) {
       *   return today+3 days 
       * } else {
       *   return electionday-42 days
       * }
       * 
       * if (no election is listed) {
       *   return today+900 days
       * }
       */
      let d = getNewVfaDate()
      let n = this.$store.state.deadlineElection

      if (n && Object.keys(n).length>0) {
        let m = new Date(n.date) // election date
        let delta = (m.getTime()-d.getTime())/MS_PER_DAY

        if (delta<this.LEO_SENDOUT_BALLOTS_TRIGGER) {
          d.setDate(d.getDate()+3)
          return d.toISOString().slice(0,10)
        } else {
          m.setDate(m.getDate()-42)
          return m.toISOString().slice(0,10)
        }
      } else {
        d.setDate(d.getDate()+900)
        return d.toISOString().slice(0,10)
      }
    },
    siteURL () {
      /**
       * 2022-11-19 John Yee
       * ExecComm wants to track which VFA site voters used.
       * e.g.
       *    this.$route.name==='index-enUS'
       *    this.$route.name==='index-esES'
       * I don't think the route name will contain "students" so I need to look at the URL.
       * 
       * What about Spanish students?
       * 
       */
      let URL = process.env.url
      let name = this.$route.name
      let path = this.$route.path
      return URL+path
    },
    VFAParameters () {
      return getVFAParameters(this.$store)
    },
    fieldCoord () {
      return this.VFAParameters['FPCA_COORDS_'+this.lang.toUpperCase()]
    },
    fvap_url () {
      return this.VFAParameters.FVAP_URL
    },
    FWAB_TRIGGER_DEFAULT () {
          return this.VFAParameters.FWAB_TRIGGER_DEFAULT
    },
    LEO_SENDOUT_BALLOTS_TRIGGER () {
      return this.VFAParameters.LEO_SENDOUT_BALLOTS_TRIGGER
    },
    butterDictionary () {
      return this.$store.state.butterDictionary
    },
    butterDictionaryWYSIWYG () {
      return this.$store.state.butterDictionaryWYSIWYG
    },
    butterStateElections () {
      return this.$store.state.butterStateElections
    },
    butterStateVotingRules () {
      return this.$store.state.butterStateVotingRules
    },
    butterVFAGraphics () {
      return this.$store.state.butterVFAGraphics
    },
    ...mapState({
      currentRequestIndex: state => state.requests.currentRequest,
      requests: state => state.requests.requests,
      canCaptureImage: state => state.userdata.device.hasWebCam,
      isIE: state => state.userdata.device.isIE
    }),
    ...mapGetters('requests', ['getCurrent', 'getCurrentDeadlines'])
  },
  filters: {
    markdown: function (md) {
      return snarkdown(md)
    },
  },
  methods: {
    beforeunloadHandler (event) {
      // * https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event * /
      event.preventDefault() // Recommended
      event.returnValue = true // Included for legacy support, e.g. Chrome/Edge < 119
    },
    removeBeforeunloadHandler () {
      window.onbeforeunload = null
    },
    isStateRulesSpecialInputTriggered (stateRulesSpecialInput, registrationStatus, abroadStatus, partyAffiliation, identificationStatus) {
      const NOTUSEDASATRIGGER = 'not-used-as-a-trigger'
      const PARTYTRIGGERVALUES = [ 'not-used-as-a-trigger', 'democratic', 'republican', 'none', 'other' ]

      let trig = true

      if (stateRulesSpecialInput.registration_trigger && 
          stateRulesSpecialInput.registration_trigger!==NOTUSEDASATRIGGER)
      {
        trig = trig && (stateRulesSpecialInput.registration_trigger.split('-or-').indexOf(registrationStatus.toLowerCase())>-1)
      }

      if (stateRulesSpecialInput.abroad_trigger && 
          stateRulesSpecialInput.abroad_trigger!==NOTUSEDASATRIGGER)
      {
        trig = trig && (stateRulesSpecialInput.abroad_trigger.split('-or-').indexOf(abroadStatus.toLowerCase())>-1)
      }

      if (stateRulesSpecialInput.party_trigger && 
          stateRulesSpecialInput.party_trigger!==NOTUSEDASATRIGGER)
      {
        let party = partyAffiliation
        if (party) {
          party = party.toLowerCase()

          // if the voter selects "Other", then the partyAffiliation becomes the name of the party.
          // that is, the partyAffiliation does not remain "Other"
          // in this case we set party to "other" so party is among the valid trigger values in stateRules.specialinput[].party_trigger
          party = (PARTYTRIGGERVALUES.indexOf(party)<0) ? "other" : party
        }

        trig = trig && (stateRulesSpecialInput.party_trigger.split('-or-').indexOf(party)>-1)
      }
    
      if (stateRulesSpecialInput.identification_trigger && 
          stateRulesSpecialInput.identification_trigger!==NOTUSEDASATRIGGER)
      {
        trig = trig && (stateRulesSpecialInput.identification_trigger.split('-or-').indexOf(identificationStatus.toLowerCase())>-1)
      }

      return trig
    },
    startSubmit () {
      this.submitMethodSelected = !this.submitMethodSelected
    },
    md: function (md) {
      return snarkdown(md)
    },
    async addSig (val) {
      this.signature = val

      /**
       * 2022-10-24 John Yee
       * ExecComm wants the date on the signed form to be the date in Washington DC at the time
       * the voter signs the form.
       */
      let formatter = new Intl.DateTimeFormat('en-US', {timeZone: 'America/New_York'});
      let ww = formatter.format(getNewVfaDate()).split('/').map(x=>x.length<2?"0"+x:x)
      let tt = ww[2]+'-'+ww[0]+'-'+ww[1]
      this.$store.commit('requests/update', { date: tt })

      this.signStep = 'composeMessage'
      await this.$nextTick()
    },
    signatureAddedDone() {
      this.signatureAdded = true
      this.postDataEntryStep='submitOptions'
    },
    voterDeadlineObject (elections, stateRules, dateFormat) {
      const vfaURL = 'www.votefromabroad.org'

      if (elections.length===0) {
        // Look for a set of submission options in the state rules. Start with registration.
        // If nothing in Registration, then cascade down.
        // If still no submission options, then assume voter may submit via postal mail.
        let so = 'mail'
        let sr = stateRules
        if (sr) { 
          so =sr.deliveryMethodRegistration.toLowerCase()
          if (!so) {
            so = sr.deliveryMethodBallotRequest.toLowerCase()
          }
          if (!so) {
            so =  sr.deliveryMethodBallotReturn.toLowerCase()
          }
        }
        so = so.replace(/-or-/g,"/")

        return {
          stateName: stateRules.name,
          rule: '',
          deadline: '',
          deadlineTime: '',
          note: '',
          voterTypeFullName: '',

          rule2: '',
          deadline2: '',
          deadlineTime2: '',
          note2: '',
          voterTypeFullName2: '',

          rule3: '',
          deadline3: '',
          deadlineTime3: '',
          note3: '',
          voterTypeFullName3: '',

          submissionMethod: so,
          alternateSubmissionMethod: so,
          electionDay: 'Jul-04, 1776',
          electionType: 'General',
          url: vfaURL, /* process.env.url, */
          state: 'US'
        }
      }

      let rule = elections[0].rule
      let deadline = new Date(elections[0].ruleDate)
      let deadlineTime = elections[0].ruleTime
      let note = elections[0].note
      let voterTypeFullName = elections[0].voterTypeFullName

      let rule2 = ""
      let deadline2 = ""
      let deadlineTime2 = ""
      let note2 = ""
      let voterTypeFullName2 = ""

      if (elections.length>1) {
        rule2 = elections[1].rule
        deadline2 = new Date(elections[1].ruleDate)
        deadlineTime2 = elections[1].ruleTime
        note2 = elections[1].note
        voterTypeFullName2 = elections[1].voterTypeFullName
      }

      let rule3 = ""
      let deadline3 = ""
      let deadlineTime3 = ""
      let note3 = ""
      let voterTypeFullName3 = ""

      if (elections.length>2) {
        rule3 = elections[2].rule
        deadline3 = new Date(elections[2].ruleDate)
        deadlineTime3 = elections[2].ruleTime
        note3 = elections[2].note
        voterTypeFullName3 = elections[2].voterTypeFullName
      }

      let methods =
        (elections.length < 2 || elections[0].submissionOptions.length > 2)
          ? ''
          : this.getDictWP(this.dict.E25, {
            method: elections[0].submissionOptions.join('/')
          })
      let altMethods =
        elections.length < 2 || elections[1].submissionOptions.length > 2
          ? ''
          : this.getDictWP(this.dict.E13, {
            rule: this.getDictFLJ(`request.deadlineLanguage.${elections[1].rule}`, this.dict),
            deadline: new Date(elections[1].ruleDate).toLocaleDateString(dateFormat, {month: 'short', day: 'numeric'}),
            deadlineTime: elections[1].ruleTime,
            method: elections[1].submissionOptions.join('/')
          })

      return {
        stateName: stateRules.name,
        rule: this.getDictFLJ(`request.deadlineLanguage.${rule}`, this.dict),
        deadline: deadline.toLocaleDateString(dateFormat, {month: 'short', day: 'numeric'}),
        deadlineTime: (deadlineTime!=='12:00AM' && deadlineTime!=='11:59PM') ? deadlineTime+' ' : '',
        note: note ? ' ('+note+' '+this.dict.H26+' "'+voterTypeFullName+'")' : '',

        rule2: rule2 ? ' or '+this.getDictFLJ(`request.deadlineLanguage.${rule2}`, this.dict) : '',
        deadline2: deadline2 ? ' '+deadline2.toLocaleDateString(dateFormat, {month: 'short', day: 'numeric'}) : '',
        deadlineTime2: deadlineTime2 ? ((deadlineTime2!=='12:00AM' && deadlineTime2!=='11:59PM') ? ' '+deadlineTime2+' ' : '') : '',
        note2: note2 ? ' ('+note2+' '+this.dict.H26+' "'+voterTypeFullName2+'")' : '',

        rule3: rule3 ? ' or '+this.getDictFLJ(`request.deadlineLanguage.${rule3}`, this.dict) : '',
        deadline3: deadline3 ? ' '+deadline3.toLocaleDateString(dateFormat, {month: 'short', day: 'numeric'}) : '',
        deadlineTime3: deadlineTime3 ? ((deadlineTime3!=='12:00AM' && deadlineTime3!=='11:59PM') ? ' '+deadlineTime3+' ' : '') : '',
        note3: note3 ? ' ('+note3+' '+this.dict.H26+' "'+voterTypeFullName3+'")' : '',

        submissionMethod: methods,
        alternateSubmissionMethod: altMethods,
        electionDay: new Date(elections[0].electionDate).toLocaleDateString(dateFormat, {month: 'short', day: 'numeric'}),
        electionType: elections[0].electionType,
        url: vfaURL, /* process.env.url, */
        state: elections[0].state
      }
    },
    saveFPCA () {
      this.$store.dispatch('requests/updateBackendFormDownloaded')
      event('fpca_downloaded')
      event('fpca_complete')

      const voterName = (this.firstName+"-"+this.lastName).replace(/ /gi, '-')
      const filename = "FPCA-"+voterName+".pdf"

      this.$buefy.dialog.alert({
        title: this.dict.G50,
        message: this.dict.N20,
        confirmText: this.dict.N21,
        type: 'is-info',
        hasIcon: true,
        onConfirm: () => {
          download(this.pdfBytesFpcaForm, filename, "application/pdf")
          this.nDownloadsFPCA++
          this.saveInstructions()
        }
      })
    },
    getPdfBytesFpcaForm(val) {
      this.pdfBytesFpcaForm = val
      this.isRenderingFpcaForm = false
    },
    getPdfBytesFpcaFormBase64(val) {
      this.pdfBytesFpcaFormBase64 = val
      this.isRenderingFpcaFormBase64 = false
      this.isRenderingFpcaForm = false
    },
    saveInstructions () {
      const voterName = (this.firstName+"-"+this.lastName).replace(/ /gi, '-')
      const filename = "FPCA-Instructions-"+voterName+".pdf"

      this.$buefy.dialog.alert({
        title: this.dict.N24,
        message: this.dict.N25,
        confirmText: this.dict.N26,
        type: 'is-info',
        hasIcon: true,
        onConfirm: () => {
          download(this.pdfBytesFpcaInstructions, filename, "application/pdf")
          this.nDownloadsInstructions++
        }
      })
    },
    getPdfBytesFpcaInstructions(val) {
      this.pdfBytesFpcaInstructions = val
      this.isRenderingFpcaInstructionSheet = false
    },
    getFpcaImagePng(val) {
      this.fpcaImagePng = val
      this.isRenderingFpcaFormImage = false
    },
    saveVFASiteData() {
      const site_domain = process.env.url.split('//')[1] // all we need is the current website's domain
      const site_language =({ "enus": "English", "eses": "Spanish" })[this.lang]
      this.$store.commit('requests/update', { VFAsite: { domain: site_domain, language: site_language }})
    },
    startNewFPCASession() {
      sessionStorage.removeItem('sessionstoragephones')
      this.$store.commit('clearVoterData')
      this.$store.dispatch('userdata/clearVoterData')
      this.$router.push(this.localePath({ name: 'index' }))
    },
    getDictFLJ(dictItem, dict) {
      /**
       * 2022-12-04 John Yee
       * This construction looks weird - like a recursive call; but, it's not.
       * The "getDictFLJ(dictItem, dict)" in the return statement is really
       * the function getDictFLJ(dictItem, dict) that is imported from ~/utils/butterUtils.js
       * 
       * reference: https://stackoverflow.com/questions/52332993/calling-a-function-from-a-helper-in-vue-template
       */
      return getDictFLJ(dictItem, dict)
    },
    getDictWP(dictItem, paramObj) {
      /**
       * 2022-12-04 John Yee
       * This construction looks weird - like a recursive call; but, it's not.
       * The "getDictWP(dictItem, paramObj)" in the return statement is really
       * the function getDictWP that is imported from ~/utils/butterUtils.js
       * 
       * reference: https://stackoverflow.com/questions/52332993/calling-a-function-from-a-helper-in-vue-template
       */
      return getDictWP(dictItem, paramObj)
    },
    getNewVfaDate() {
      return getNewVfaDate()
    },
    getStageAfterReview() {
      /*
      2024-07-27 John Yee
      conversation with Heidi Burch
      
      Some states treat registered and unregistered voters differently with respect to accepting a photo signature.
      This function determines whether or not the voter is offered the photo signature option.
      */

      let nextStage

      if (!this.stateRules.usesvoterisregistered) {
        nextStage=(this.stateRules.allowsscannedsignature && this.LEOAcceptsEmailNow) ? 'signaturePhotoImage' : 'submitOptions'
      } else {
        switch(this.currentRequest.isRegistered) {
          case 'unsure': nextStage='specialStates';
                break;
          case 'notregistered': nextStage=this.StateAcceptsEmailNowFromUnregisteredVoter ? 'signaturePhotoImage' : 'submitOptions';
                break;
          case 'registered': nextStage=(this.stateRules.allowsscannedsignature && this.LEOAcceptsEmailNow) ? 'signaturePhotoImage' : 'submitOptions';
                break;
          default: nextStage='submitOptions';
        }
      }

      return nextStage
    },
    goSubmit() {
      this.isRegisteredVoter = this.voterSelectedRegistration
      this.$store.commit("saveVoterIsRegistered", this.voterSelectedRegistration)
      this.postDataEntryStep=(this.voterSelectedRegistration==='registered' && this.stateRules.allowsscannedsignature && this.LEOAcceptsEmailNow) ? "signaturePhotoImage" : "submitOptions"
    },
    goFromReview() {
      this.isSigning=false
      this.signatureAdded=false
      this.signature=''
      this.submitMethodSelected=false
      this.submitMethod=''
      this.postDataEntryStep=this.getStageAfterReview()
      this.saveVFASiteData()
      this.$store.dispatch('requests/updateBackendFormReviewed')
      event('fpca_reviewed')
      event('fpca_incomplete')
    },
    ...mapMutations("requests", ["update"])
  },
}
